import {
  ref,
  computed,
  readonly,
} from '@vue/composition-api'
// import instruments from '@/data/instruments.json'
import useApollo from '@/plugins/apolloClient'
import { gql } from '@apollo/client/core'

const instrument = ref({
  active: {
    id: 1,
    name: 'AUD_USD',
  },
  isFiltered: false,
  list: [],
})

export default function useInstruments() {
  const frontSchema = process.env.VUE_APP_HASURA_SCHEMA_FRONT
  const { http } = useApollo()

  function setInstrumentActive({ newInst }) {
    instrument.value.active.name = newInst.name
    instrument.value.active.id = newInst.id
  }

  async function getInstruments() {
    await http.value.query({
      query: gql`
        query getInstruments {
         ${frontSchema}_instruments {
            id
            name
          }
        }
      `,
    })
      .then(result => {
        instrument.value.list = result.data[`${frontSchema}_instruments`]
      })
      .catch(err => console.error('getInstruments', err))
  }

  function toggleInstrumentFilter(boolean) {
    instrument.value.isFiltered = boolean
  }

  return {
    instrumentActive: computed(() => instrument.value.active),
    instrumentList: computed(() => readonly(instrument.value.list)),
    instrumentFilter: computed(() => instrument.value.isFiltered),
    toggleInstrumentFilter,
    setInstrumentActive,
    getInstruments,
  }
}
