<template>
  <div
    class="content"
    :class="[{
      'app-content': $route.path !== '/trading',
      'no-navbars': $route.path === '/community',
      'show-overlay': $store.state.app.shallShowOverlay,
      'no-padding': !noPaddingRouteNames.includes($route.name),
    }, $route.meta.contentClass]"
  >

    <div class="content-overlay" />
    <div
      class="header-navbar-shadow"
    />
    <div
      class="content-wrapper"
      :class="contentWidth === 'boxed' ? 'container p-0' : null"
    >

      <div class="content-body">
        <transition
          :name="routerTransition"
          mode="out-in"
        >
          <slot />
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import useAppConfig from '@core/app-config/useAppConfig'

export default {
  data() {
    return {
      noPaddingRouteNames: ['error-404', 'success', 'payment-failed', 'leaderboard'],
    }
  },
  setup() {
    const { routerTransition, contentWidth } = useAppConfig()

    return {
      routerTransition, contentWidth,
    }
  },
}
</script>

<style>
</style>
