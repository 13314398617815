import {
  ref,
  computed,
} from '@vue/composition-api'

const tabs = ref({
  pageName: 'Home',
  pagePath: '/home',
  home: {
    activeIndex: 0,
    activeId: 'tabButtonNew',
    tabs: [
      { index: 0, name: 'New' },
      { index: 1, name: 'Community' },
      { index: 2, name: 'Journal' },
      { index: 3, name: 'Study' },
      { index: 4, name: 'Tutorials' },
      { index: 5, name: 'Updates' },
    ],
  },
  trading: {
    activeIndex: 0,
    activeId: 'tabButtonSignals',
    tabs: [
      { index: 0, name: 'Signals' },
      { index: 1, name: 'Positions' },
      { index: 2, name: 'Journal' },
    ],
  },
  analysis: {
    activeIndex: 0,
    activeId: 'tabButtonHermesTrades',
    tabs: [
      { index: 0, name: 'Hermes Trades' },
      // { index: 0, name: 'Human Trades' },
      // { index: 1, name: 'Hybrid Trades' },
    ],
  },
  strategies: {
    activeIndex: 0,
    activeId: 'tabButtonHermesStrategies',
    tabs: [
      { index: 0, name: 'Hermes Strategies' },
      // { index: 0, name: 'Human Strategies' },
      // { index: 1, name: 'Hybrid Strategies' },
    ],
  },
  leaderboard: {
    activeIndex: 0,
    activeId: 'tabButtonHermesTrades',
    tabs: [
      { index: 0, name: 'Hermes Trades' },
      // { index: 0, name: 'Human Trades' },
      // { index: 1, name: 'Hybrid Trades' },
    ],
  },
  profile: {
    activeIndex: 0,
    activeId: 'tabButtonGeneral',
    tabs: [
      { index: 0, name: 'General' },
      { index: 1, name: 'Plans' },
    ],
  },
  journal: {
    activeIndex: 0,
    activeId: 'tabButtonJournal',
    tabs: [
      { index: 0, name: 'Journal' },
      // { index: 1, name: 'Metrics' },
    ],
  },
  performance: {
    activeIndex: 0,
    activeId: 'tabButtonHermes',
    tabs: [
      { index: 0, name: 'Hermes' },
      // { index: 0, name: 'Human' },
      // { index: 0, name: 'Hybrid' },
      // { index: 1, name: 'Charts' },
    ],
  },
})

export default function useTabs() {
  // const frontSchema = process.env.VUE_APP_HASURA_SCHEMA_FRONT

  const currentPage = computed(() => tabs.value.pageName)
  const indexActive = computed(() => tabs.value[currentPage.value].activeIndex)

  function setPage({ name, path }) {
    tabs.value.pageName = name
    tabs.value.pagePath = path
  }

  function setTabActive({ tab }) {
    // removed active styling
    const { activeId } = tabs.value[currentPage.value]
    const prevActive = document.getElementById(`${activeId}`)
    prevActive.classList.remove('active')

    // set active styling
    const tabName = tab.name.replace(/\s+/g, '')
    const nowActive = document.getElementById(`tabButton${tabName}`)
    nowActive.classList.add('active')

    // change tab and store tab_id for removing style
    tabs.value[currentPage.value].activeIndex = tab.index
    tabs.value[currentPage.value].activeId = `tabButton${tabName}`
  }

  return {
    tabPage: computed(() => tabs.value[currentPage.value]),
    tabActive: computed(() => tabs.value[currentPage.value].tabs[indexActive.value]),
    pageName: computed(() => tabs.value.pageName),
    pagePath: computed(() => tabs.value.pagePath),
    setTabActive,
    setPage,
  }
}
