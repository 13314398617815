// using fontawesome icons

const menu = [
  {
    title: 'Home',
    route: 'home',
    icon: 'fa-home',
  },
  {
    title: 'Trading',
    route: 'trading',
    icon: 'fa-chart-area',
  },
  // {
  //   title: 'Journal',
  //   route: 'journal',
  //   icon: 'fa-list-alt',
  // },
  {
    title: 'Strategies',
    route: 'strategies',
    icon: 'fa-chess-queen',
  },
  {
    title: 'Performance',
    route: 'performance',
    icon: 'fa-tachometer-alt',
  },
  {
    title: 'Analysis',
    route: 'analysis',
    icon: 'fa-eye',
  },
  {
    title: 'Leaderboard',
    route: 'leaderboard',
    icon: 'fa-trophy',
  },
  {
    title: 'Community',
    route: 'community',
    icon: 'fa-users',
  },
  // {
  //   title: 'Media Library',
  //   route: 'media-library',
  //   icon: 'fa-trophy',
  // },
]

export default menu
