<template>
  <ul>
    <component
      :is="resolveNavItemComponent(item)"
      v-for="item in filteredItems"
      v-show="$auth.isAuthenticated"
      :key="item.header || item.title"
      :item="item"
    />
  </ul>
</template>

<script>
import { provide, ref } from '@vue/composition-api'
import useUserSingle from '@/composables/users/useUserSingle'
import { resolveVerticalNavMenuItemComponent as resolveNavItemComponent } from '../utils'
import VerticalNavMenuLink from '../menu-link/VerticalNavMenuLink.vue'
import adminPages from '../adminPages.json'

export default {
  components: {
    VerticalNavMenuLink,
  },
  props: {
    items: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  setup() {
    provide('openGroups', ref([]))

    const { user } = useUserSingle()

    return {
      resolveNavItemComponent,
      user,
    }
  },
  computed: {
    filteredItems() {
      if (this.user.role === 'admin') {
        return this.items
      } return this.items.filter(i => !adminPages.includes(i.route))
    },
  },
}
</script>
