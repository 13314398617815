<template>
  <layout-vertical-comp>
    <router-view />
    <template
      #navbar="{ toggleVerticalMenuActive }"
    >
      <navbar
        :toggle-vertical-menu-active="toggleVerticalMenuActive"
      />
    </template>
  </layout-vertical-comp>
</template>

<script>
import Navbar from './Navbar.vue'
import LayoutVerticalComp from './vertical/LayoutVerticalComp.vue'

export default {
  components: {
    Navbar,
    LayoutVerticalComp,
  },
  data() {
    return {
      // blank
    }
  },
}
</script>
