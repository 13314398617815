export default function useCloudinary() {
  const base = 'https://res.cloudinary.com/mlfx/image/upload/f_auto/'

  const transforms = {
    article: {
      featured: 'c_scale,w_1200',
      body: 'c_scale,w_900',
      card: 'c_scale,w_640',
    },
    avatar: {
      small: 'c_scale,w_60',
      large: 'c_scale,w_180',
    },
  }

  function imgWithTransforms({ path, type }) {
    const tran = transforms[type[0]][type[1]]
    const url = `${base}${tran}/${path}`
    return url
  }

  return {
    imgWithTransforms,
  }
}
