import {
  ref,
  // computed,
} from '@vue/composition-api'
// import useUserSingle from './users/useUserSingle'

const rules = ref({
  freeTrialDays: 60,
  freePages: [/payment-failed/, /^\/profile/, /undefined/, /success/, /^\/home/, /^\/$/, /^\/leaderboard/], // has to be regex
  adminPages: [/strategies/, /analysis/, /^\/admin/, /leaderboard/],
})

export default function useRules() {
  // const { user } = useUserSingle()

  return {
    rules,
  }
}
