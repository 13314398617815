import { computed, ref } from '@vue/composition-api'

const markets = ref({
  isOpen: false,
  opensIn: {
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  },
  currentSession: {},
  sessions: [],
})

export default function useMarketSessions() {
  //
  async function createMarketSessions() {
    const date = new Date()
    const day = date.getDate()
    const month = date.getMonth()
    const year = date.getFullYear()
    // we should make this dynamic, fetch from API or something
    markets.value.sessions.push(
      // Previous
      { name: 'London Open', date: new Date(Date.UTC(year, month, day - 1, 8)) },
      { name: 'London Close', date: new Date(Date.UTC(year, month, day - 1, 16)) },
      { name: 'New York Open', date: new Date(Date.UTC(year, month, day - 1, 13)) },
      { name: 'New York Close', date: new Date(Date.UTC(year, month, day - 1, 21)) },
      { name: 'Tokyo Open', date: new Date(Date.UTC(year, month, day - 1, 23)) },
      { name: 'Tokyo Close', date: new Date(Date.UTC(year, month, (day), 7)) },
      // Upcoming
      { name: 'London Open', date: new Date(Date.UTC(year, month, day, 8)) },
      { name: 'London Close', date: new Date(Date.UTC(year, month, day, 16)) },
      { name: 'New York Open', date: new Date(Date.UTC(year, month, day, 13)) },
      { name: 'New York Close', date: new Date(Date.UTC(year, month, day, 21)) },
      { name: 'Tokyo Open', date: new Date(Date.UTC(year, month, day, 23)) },
      { name: 'Tokyo Close', date: new Date(Date.UTC(year, month, (day + 1), 7)) },
    )
  }

  function nextMarketSession() {
    const now = new Date()
    const upcoming = markets.value.sessions.filter(s => (s.date - now) > 0)
    const upcomingOrdered = upcoming.sort((a, b) => a.date - b.date)
    return upcomingOrdered[0] || null
  }

  function prevMarketSession() {
    const now = new Date()
    const previos = markets.value.sessions.filter(s => (s.date - now) < 0)
    const previosOrdered = previos.sort((a, b) => a.date - b.date)
    const latest = previosOrdered.length - 1
    return previosOrdered[latest] || null
  }

  function checkMarketIsOpen() {
    // make dynamic, fetch from API or poll Oanda
    if (!markets.value.isOpen) {
      const today = new Date()
      const day = today.getUTCDay()
      let daysToSunday = 0
      if (day !== 0) {
        daysToSunday = 7 - day
      }
      // markets open Sunday 22:00 UTC
      const marketOpens = new Date(
        Date.UTC(
          today.getUTCFullYear(),
          today.getUTCMonth(),
          today.getUTCDate() + daysToSunday,
          22,
        ),
      )
      // Calculate how long that is from now
      const now = new Date().getTime()
      const msToOpen = marketOpens - now

      // Convert into days, hours, minutes and seconds
      const aMintue = 1000 * 60
      const aHour = aMintue * 60
      const aDay = aHour * 24
      const hoursLeft = String(Math.floor((msToOpen % aDay) / aHour)).padStart(2, '0')
      const tillOpen = `${Math.floor(msToOpen / aDay)}${hoursLeft}`
      if (tillOpen >= 2 && tillOpen < 203) {
        markets.value.isOpen = false
        // String(Math.trunc((ageInSeconds / 60) % 60)).padStart(2, 0)
        markets.value.opensIn = {
          days: Math.floor(msToOpen / aDay),
          hours: String(Math.floor((msToOpen % aDay) / aHour)).padStart(2, 0),
          minutes: String(Math.floor((msToOpen % aHour) / aMintue)).padStart(2, 0),
          seconds: String(Math.floor((msToOpen % aMintue) / 1000)).padStart(2, 0),
        }
      } else {
        markets.value.isOpen = true
      }
    }
  }

  return {
    marketOpenStatus: computed(() => markets.value.isOpen),
    marketNextSession: computed(() => nextMarketSession()),
    marketPrevSession: computed(() => prevMarketSession()),
    marketSessions: computed(() => markets.value.sessions),
    marketOpensIn: computed(() => markets.value.opensIn),
    checkMarketIsOpen,
    createMarketSessions,
  }
}
