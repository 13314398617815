<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Left Col -->
    <div class="brand-section">
      <!-- Logo & Text -->
      <!-- <button @click="testFunc">test</button> -->
      <div class="brand-logo">
        <span class="brand-favicon">
          <router-link to="/">
            <b-img
              v-if="skin === 'light'"
              class="favicon"
              :src="appFaviconImageBlack"
              alt="MLFX favicon"
            />
            <b-img
              v-else
              class="favicon"
              :src="appFaviconImageWhite"
              alt="MLFX favicon"
            />
          </router-link>
        </span>
      </div>
      <!-- Nav Menu Toggler -->
      <ul class="nav navbar-nav hamburger d-xl-none">
        <li class="nav-item">
          <b-link
            class="nav-link"
            @click="toggleVerticalMenuActive"
          >
            <i class="fas fa-bars" />
          </b-link>
        </li>
      </ul>
      <app-breadcrumb />
      <!-- <Banner /> -->
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <div v-if="!$auth.loading">
        <button
          v-if="!$auth.isAuthenticated"
          class="btn btn-outline-dark"
          @click="login"
        >
          Login
        </button>
        <b-nav-item-dropdown
          v-else
          right
          toggle-class="d-flex align-items-center dropdown-user-link"
          class="dropdown-user"
        >
          <template #button-content>
            <div class="d-sm-flex d-none user-nav">
              <p class="user-name font-weight-bolder mb-0">
                {{ user.name }}
              </p>
              <span class="user-status">{{ user.plan }} plan</span>
            </div>
            <b-avatar
              v-if="user.avatar"
              size="40"
              variant="light-primary"
              badge
              :src="
                imgWithTransforms({
                  path: user.avatar,
                  type: ['avatar', 'small'],
                })
              "
              class="badge-minimal"
              badge-variant="success"
            />
          </template>

          <b-dropdown-item :to="{ name: 'profile' }">
            <i class="fas fa-user pr-1" />
            <span>Profile</span>
          </b-dropdown-item>

          <!-- <b-dropdown-divider /> -->

          <!-- <b-dropdown-item
            :href="github"
            target="_blank"
          >
            <i class="fas fa-lightbulb pr-1" />
            <span> Feedback </span>
          </b-dropdown-item> -->

          <b-dropdown-divider />

          <b-dropdown-item link-class="d-flex align-items-center">
            <i class="fas fa-sign-out-alt pr-1" />
            <span
              v-if="$auth.isAuthenticated"
              @click="logout"
            > Logout </span>
          </b-dropdown-item>
        </b-nav-item-dropdown>
      </div>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink,
  BNavbarNav,
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
  BImg,
} from 'bootstrap-vue'
import { $themeConfig } from '@themeConfig'
import router from '@/router/index'
import useAppConfig from '@core/app-config/useAppConfig'
import useInstruments from '@/composables/useInstruments'
import useUserSingle from '@/composables/users/useUserSingle'
import useTabs from '@/composables/useTabs'
import useCategories from '@/composables/useCategories'
import useCloudinary from '@/composables/useCloudinary'
import useMarketSessions from '@/composables/useMarketSessions'
import useRules from '@/composables/useRules'
import useStripe from '@/composables/stripe/useStripe'
// import { watch, ref } from '@vue/composition-api'

// import Banner from './Banner.vue'
import navMenuItems from './vertical/index'
import AppBreadcrumb from './AppBreadcrumb.vue'

export default {
  components: {
    BLink,
    BImg,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    AppBreadcrumb,
    // Banner,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      github: process.env.VUE_APP_GITHUB_DISCUS_URL,
    }
  },
  setup() {
    const { appName, appFaviconImageWhite, appFaviconImageBlack } = $themeConfig.app

    const { getUser, user } = useUserSingle()

    const { skin } = useAppConfig()

    const { getInstruments } = useInstruments()

    const { getCategories } = useCategories()

    const { redirectStripeCheckout } = useStripe()

    const { imgWithTransforms } = useCloudinary()
    const { checkMarketIsOpen } = useMarketSessions()
    checkMarketIsOpen()

    const { rules } = useRules()

    const { setPage, pagePath } = useTabs()

    function mustUpgradePlan({ u = user.value, p = pagePath.value }) {
      const ctaWrap = document.getElementById('ctaWrap')
      ctaWrap.style.display = 'flex'
      ctaWrap.innerHTML = `
      <div class="cta-card">
        <h6> Hi ${u.name}</h6>
        <span> ${p.slice(1)} is a premium service </span>
        <span class="button primary cta-button"> Please Upgrade</span>
        <span @click-"redirectStripeCheckout({ price: 'price_1JlQWxDhvT9geSyOPmRlRnhE' })"> Your plan/trial has expired </span>
      </div>`
    }

    function userAccessToPage({ u = user.value, p = pagePath.value }) {
      // Admin has full access
      if (u.role === 'admin') return true

      // If not admin & page is admin only, redirect home
      if (rules.value.adminPages.some(isPageAdmin => isPageAdmin.test(p))) return router.push({ name: 'home' })

      // The page is free to all
      if (rules.value.freePages.some(freePage => freePage.test(p))) return true

      // The page is paid, but user has gold plan
      if (u.plan === 'gold') return true

      // The page is paid, check if free trial is valid
      const today = new Date().getTime()
      const dayInMili = 86400000
      const daysSinceSignup = Math.abs(new Date(u.created_at).getTime() - today) / dayInMili
      if (rules.value.freeTrialDays > daysSinceSignup) return true

      // The user does not have access to the paid page
      return mustUpgradePlan({ u, p })
    }

    return {
      userAccessToPage,
      redirectStripeCheckout,
      navMenuItems,
      setPage,
      imgWithTransforms,

      // Skin
      skin,
      // App Branding
      appName,
      appFaviconImageBlack,
      appFaviconImageWhite,
      //
      user,
      getUser,
      getInstruments,
      getCategories,
    }
  },
  computed: {
    page() {
      return {
        name: this.$route.name,
        path: this.$route.path,
      }
    },
    token() {
      return this.$auth.token
    },
    // fullUser() {
    //   return this.user
    // },
  },
  watch: {
    page() {
      this.setPage({ name: this.page.name, path: this.page.path })
        .then(() => this.userAccessToPage({ p: this.page.path, u: this.user }))
    },
    token() {
      this.getUser().then(() => {
        // show modal cta if not valid
        this.userAccessToPage({ p: this.page.path, u: this.user })
      })
      this.getInstruments()
      this.getCategories()
    },
  },
  methods: {
    // Log the user in
    login() {
      this.$auth.loginWithRedirect()
    },
    // Log the user out
    logout() {
      this.$auth.logout({
        returnTo: 'https://www.mlforex.com/',
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.brand-section {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  justify-content: left;
  .hamburger {
    margin: 0;
    padding: 0;
    z-index: 100;
    .nav-item {
      height: 100%;
      display: flex;
      align-items: center;
    }
  }
}
.brand-logo {
  display: flex;
  align-items: center;
  .favicon {
    max-height: 32px;
  }
}
</style>
