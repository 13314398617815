<template>
  <b-row
    v-if="$route.meta.breadcrumb || $route.meta.pageTitle"
    class="content-header"
  >

    <!-- Content Left -->
    <b-col
      class="content-header-left"
      cols="12"
    >
      <b-row class="breadcrumbs-top">
        <b-col cols="12">
          <div class="breadcrumb-wrapper">
            <b-breadcrumb class="d-flex align-items-center">
              <p
                class="breadcrumb-separator"
                :style="{ 'margin-right': '1.5rem' }"
              >|
              </p>
              <b-breadcrumb-item
                v-for="item in $route.meta.breadcrumb"
                :key="item.text"
                :active="item.active"
                :to="item.to"
              >
                {{ item.text }}
              </b-breadcrumb-item>
            </b-breadcrumb>
          </div>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>
<script>
import {
  BBreadcrumb, BBreadcrumbItem, BRow, BCol,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    Ripple,
  },
  components: {
    BBreadcrumb,
    BBreadcrumbItem,
    BRow,
    BCol,
  },
}
</script>

<style lang="scss">
.breadcrumb-separator {
  line-height: 0px;
  margin: 0px;
}
</style>
